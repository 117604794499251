import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import HornImg from "../images/luidsprekerhartjes.png"
import WinkelImg from "../images/winkeltje.png"
import VrachtawagentjeImg from "../images/vrachtwagentje.png"
import WinkelmandjeImg from "../images/winkelmandje.png"
import SpaarvarkenImg from "../images/spaarvarkentje.png"
import ContactImg from "../images/envelopje.png"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from "gatsby";

import aImg from "../images/deceulenerpics/Foto_1.jpg"
import bImg from "../images/selection/inspiratie_01.jpg"
import cImg from "../images/selection/inspiratie_02.jpg"
import dImg from "../images/selection/inspiratie_03.jpg"
import eImg from "../images/selection/inspiratie_04.jpg"
import fImg from "../images/selection/inspiratie_05.jpg"
import hImg from "../images/selection/inspiratie_06.jpg"
import iImg from "../images/deceulenerpics/Foto_3.jpg"
import jImg from "../images/dc/person_01.jpg"
import kImg from "../images/dc/slide-jab-anstoetz-group-living-world-belle-couture-m02-l.jpeg"
import mImg from "../images/deceulenerpics/Foto_5.jpg"
import nImg from "../images/deceulenerpics/Foto_7.jpg"
import oImg from "../images/deceulenerpics/Foto_8.jpg"
import pImg from "../images/deceulenerpics/Foto_9.jpg"
import qImg from "../images/selection/aanpak_02.jpg"
import rImg from "../images/selection/aanpak_03.jpg"
import Imgb from "../images/deceulenerpics/Foto_2.jpg"
import lImg from "../images/deceulenerpics/Foto_3.jpg"
import Img5 from "../images/deceulenerpics/Foto_5.jpg"
import Img4 from "../images/deceulenerpics/Foto_4.jpg"
import Imgf from "../images/deceulenerpics/Foto_6.jpg"


const Index = () => (
  <Layout >
    <section className="text-gray-600 body-font bg-gray-300">
		<div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
	    	<div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">		
				<h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Deceulener Interieur
				</h1>
				<p className="mb-8 leading-relaxed">Vriendelijk onthaal, advies en begeleiding in een zoektocht naar sfeer en originaliteit,omdat wij ons richten tot de consument met hoge eisen en steeds streven naar perfectie.</p>
				<p className="mb-8 leading-relaxed">Wij blijven geloven in evolutie, kwaliteit,vakmanschap en service om uw droom,uw interieur een persoonlijke, creatieve uitstraling te geven..</p>
			</div>	
			
		    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
		    	    
						<img className="object-cover object-center rounded" src={aImg} alt="Listen" />
					
		    </div>
	    </div>
	</section>	
	
	<section id="kracht" className="text-gray-600 body-font bg-gray-300">
  <div className="container px-5 py-24 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Onze kracht</h1>
      <center><div className="h-1 w-20 bg-black rounded"></div></center>
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto pt-10">Onze familiezaak bestaat sinds 1946 en wij handelen nog steeds vanuit onze kernwaarden.</p>
    </div>
    <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Vriendelijk onthaal</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Persoonlijk advies en begeleiding</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Streven naar perfectie</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Oog voor detail en schoonheid</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Uitstekende Service én Na-Service</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Stijl, kwaliteit en vakmanschap</span>
        </div>
      </div>
    </div>
  </div>
</section>
	
	
	
	
	
	
	<section id="inspiratie" className="text-gray-600 body-font">
		<div className="container px-5 py-24 mx-auto">
		    <div className="flex flex-wrap w-full mb-20">
				<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
					<h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Inspiratie</h1>
					<div className="h-1 w-20 bg-black rounded"></div>
				</div>
				<p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Wij vinden het fijn om te werken aan de dromen van onze klanten. Daarom zijn de projecten die we realiseren voor ons ook steeds een beetje als thuiskomen.</p>
			</div>
    
    
    
    <div className="flex flex-wrap md:-m-2 -m-1">
      <div className="flex flex-wrap w-1/2">
        <div className="md:p-2 p-1 w-1/2">
        
        		<a href="/contact">
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={Img5}></img>
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
          
          	
          
        </div>
        <div className="md:p-2 p-1 w-1/2">
        
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={Img4}></img>
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
        		
        	
				
			
        </div>
        <div className="md:p-2 p-1 w-full">
        
        	
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={hImg}></img>
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
				
			
        </div>
      </div>
      <div className="flex flex-wrap w-1/2">
        <div className="md:p-2 p-1 w-full">
        		
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={iImg}></img>
			
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
			
        </div>
        <div className=" group md:p-2 p-1 w-1/2">
        		
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={kImg}></img>
			
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
				
		        </div>
        
        <div className="md:p-2 p-1 w-1/2">
        		
        		<a href="/contact">
        		
						<div className="rounded w-full object-cover h-full object-center block" style={{backgroundImage: 'url('+Imgb+')', backgroundSize: "cover"}}> 
							<div className="group">
							</div>
						</div> 
				
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
				
				
				  
        </div>
        
        

        
      </div>
    </div>
  </div>
</section>
	
	
	
	
	
	
	
	


	<section className="text-gray-600 body-font bg-gray-300">
  <div className="container px-5 py-24 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Uw interieur, uw droom … ons beroep</h1>
      <center><div className="h-1 w-20 bg-black rounded"></div></center>
    </div>
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <p className="mt-8 text-xl font-light leading-relaxed">
            Onze collectie van vitrages en overgordijnen met alle bijbehorigheden worden voortdurend vernieuwd. 
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
          Bij ons kan u terecht voor functionele en decoratieve raambekleding zoals:
          <li>Vouw-, plooi -en rolgordijnen </li>
          <li>Plissés, duetten en silhouette shades</li>
          <li>Horizontale, verticale en houten jaloezieën</li>
			</p>
			 <p className="mt-8 text-xl font-light leading-relaxed">
            Verder bieden wij u een ruim assortiment aan trendy tapijten en vast tapijt, exact op maat gemaakt.
			</p>
			<p className="mt-8 text-xl font-light leading-relaxed">
            elektrische toepassingen zijn mogelijke voor wie iets meer wil. Dankzij de nieuwste smarthomebediening met powerview automation beslis je wanneer je raamdecoratie zichzelf automatisch opent, sluit of aanpast en dit elk moment van de dag of nacht. 
			</p>
        </div>
      }
      secondarySlot={<img src={lImg} alt="Listen" />}
    />
        	
        </div>	
    </section>
    
    
   	<section id="gamma" className="text-gray-600 body-font">
		<div className="container px-5 py-24 mx-auto">
		    <div className="flex flex-wrap w-full mb-20">
				<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
					<h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Ons aanbod</h1>
					<div className="h-1 w-20 bg-black rounded"></div>
				</div>
				<p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Wij werken enkel met materialen van topleveranciers. Wij kunnen daarbij rekenen op een jarenlange samenwerking met gerenommeerde merken en we zorgen dan ook voor een professionele installatie. Zodat u er snel van kan genieten.</p>
			</div>
        
        
        
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">LUXAFLEX - the art of window styling</p>
              <p className="mt-4 pb-4">
			  	Bij Luxaflex beginnen prachtige producten met een krachtig ontwerp. Al 70 jaar staat Luxaflex voor exclusieve en op maat gemaakte raambekleding voor het hele huis.</p>
              {<img className="rounded" src={mImg} alt="Listen" />}
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">JAB ANSTOETZ - gordijnen en overgordijnen</p>
              <p className="mt-4 pb-4">
                De grootste editeur ter wereld van stoffen voor raam -en zetelbekleding zowel in klassieke, moderne als designstijl. 
              </p>
              {<img className="rounded" src={nImg} alt="Listen" />}
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">JAB ANSTOETZ - tapijten en vasttapijt</p>
              <p className="mt-4 pb-4">
			  	Precies op maat gemaakt, met of zonder omranding. Kwaliteiten in wol, synthetisch en zelfs gerecycleerde eco-vezels.</p>
              {<img className="rounded" src={oImg} alt="Listen" />}
            </Card>
          </div>
        </div>
      </div>
    </section>
    
    
    	<section id ="aanpak" className="text-gray-600 body-font bg-gray-300">
  <div className="container px-5 py-24 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Aanpak</h1>
      <center><div className="h-1 w-20 bg-black rounded"></div></center>
      
    </div>
    
    
    
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Stap 1 : We luisteren naar uw behoeften met aandacht voor uw persoonlijke smaak.</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          	Door onze ervaring en uitgebreid aanbod aan producten en stijlen komen we tot een gezamenlijk zicht op de behoeften en voorkeuren.
          </p>
        </div>
      }
      secondarySlot={<img src={pImg} alt="Listen" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Stap 2 : Zorgeloos maar zorgvuldig kiezen
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
		  	Door te kijken en te voelen aan de materialen en ons professioneel advies komen we meestal vrij snel tot een aantal keuzemogelijkheden die passen bij uw persoonlijke stijl.</p>
        </div>
      }
      secondarySlot={<img src={qImg} alt="Listen" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Stap 3 : Levering en plaatsing
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          	Wij zorgen voor een probleemloze levering en installatie van de producten. Er rest u enkel nog te genieten.
          </p>
        </div>
      }
      secondarySlot={<img src={rImg} alt="Listen" />}
    />
    
      </div>
    </section>
    
    

    
    
    
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">		
			<div>
				<h3 className="text-5xl font-semibold">Kunnen we je nog verder overtuigen ?</h3>
				<p className="mt-8">
					<Link to="/contact" className="px-4">
						<button size="xl" className="inline-flex text-white bg-black border-0 py-2 px-6 focus:outline-none hover:bg-gray-500 rounded text-lg">Contacteer ons.</button>
					</Link>
					
				</p>
				
		
		</div>	
    </section>
    
        
    
  </Layout>
);

export default Index;
